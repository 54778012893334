.status-cofig-table {
    .slop-time {
        display: flex;
        justify-content: flex-start;
        margin-top: 20px;

        .ant-input-number.slop-input {
            width: 100px;
            margin-right: 35px;

            input.ant-input-number-input {
                margin-left: -8px;
                margin-top: 3px;
            }
        }

        span.slop-span {
            margin: 6px 22px 0 0;
        }

        .ant-select.status-selector.ant-select-single.ant-select-show-arrow {
            margin-left: 22px;
            width: 14pc;
        }

        .status-selector {
            width: 20%;

            .ant-select-selector {
                border-radius: 16px !important;
                height: 34px !important;
                background: $table-bg-white;
            }
        }
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        margin-top: 2px;
    }

    .ant-select.status-selector.ant-select-single.ant-select-show-arrow.ant-select-disabled {
        span.ant-select-selection-item {
            color: $Grey;
        }
    }

    .status-config-tb-btn {
        margin-top: 20px;
        display: flex;
        justify-content: end;
    }

    .status-cancel-btn {
        margin-right: 20px;
        border-radius: 18px;
        border: 1px solid $baseWhite;
        width: 10%;
        height: 35px;
    }

    .status-submit-btn {
        border-radius: 18px;
        width: 10%;
        height: 35px;
    }

    .ant-table-tbody tr.ant-table-row:nth-child(even) {
        background: none;
    }

    .status-tb-title {
        display: flex;
        justify-content: space-between;
    }

    th.ant-table-cell.rowtitle.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
        background: #110001;
    }

    td.ant-table-cell.rowtitle.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
        display: flex;
        justify-content: center;
        background: #110001;
    }

    td.ant-table-cell.rowtitle.ant-table-cell-fix-left.ant-table-cell-fix-left-last.ant-table-cell-row-hover {
        background: #110001 !important;
    }

    tr:nth-child(even) td:first-child {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    .ant-input-number.no-arrows.ant-input-number-disabled {
        height: 30px;
    }


    .status-table-limit {
        overflow-x: auto;
        max-width: 1665px;

        .no-arrows .ant-input-number-handler-wrap {
            display: none;
        }

        .ant-select.job-number-selector.ant-select-single.ant-select-show-arrow {
            margin-left: 12px;
            margin-bottom: 12px;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            border-radius: 16px;
        }
        .ant-table table {
            min-width: 0px !important;
        }
    }

    span.ant-checkbox.ant-checkbox-checked {
        border: 1px solid $baseRed;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: $baseRed;
        border-color: $baseRed;
    }

    .ant-checkbox-inner {
        border-color: $baseRed;
    }

    .ant-table-wrapper .ant-spin-nested-loading .ant-table-tbody>tr>td,
    .ant-table-wrapper .ant-spin-container .ant-table-tbody>tr>td,
    .ant-table-wrapper .ant-table .ant-table-tbody>tr>td {
        text-align: center;
        padding: 2px;
        justify-content: center;
    }

    .ant-input-number {
        width: 90%;
        height: 35px;
    }

    input.ant-input-number-input {
        text-align: center;
    }

    .ant-select-arrow .anticon {
        color: $baseRed;
    }

    .rowtitle {
        p {
            position: relative;
            top: 5px;
        }
    }

    .ant-table-wrapper .ant-table .ant-table-thead>tr>th {
        border: none;
    }

    .input-select-flex {
        display: flex;
        flex-direction: row;
    }
}


.ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
    border: 1px solid $baseRed;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid $baseRed;
}